import en from "../public/locales/en/routes.json";
import de from "../public/locales/de/routes.json";
import { Locale } from "@travellocal/utils";

type RouteTable = Record<string, Record<string, string>>;

/** Localised routes use {{foo}} syntax to represent parameters. */
// eslint-disable-next-line no-useless-escape
const L10N_ROUTE_PARAM_REGEX = /\{\{[^\/]+\}\}/g;

/**
 * Routes of the application. Add new translated routes as an entry here.
 */
const LOCALISED_ROUTES: RouteTable = { en, de };

/**
 * Routes of the en route table that should be served from Site 3. Others are served from Site 2.
 */
export const SITE3_ROUTE_KEYS: (keyof typeof en)[] = [
  "404",
  "article",
  "articleBySlug",
  "articles",
  "collection",
  "collectionBySlug",
  "collections",
  "country",
  "customerIndex",
  "destinations",
  "destinationsByCountry",
  "destinationsByCountryExperiment",
  "destinationsRequestForm",
  "forgottenPassword",
  "howItWorks",
  "index",
  "login",
  "maintenance",
  "regionProfileBySlug",
  "cookiePolicy",
  "requestThanks",
  "exact-trip-request",
  "reimagineAnimalWelfare",
  "reimagineBusinessAsAForceForGood",
  "reimagineCertifiedBCorporation",
  "reimagineEnvironment",
  "reimagineFundForGood",
  "reimagineOurCommitment",
  "reimagineOverview",
  "reimaginePeopleAndSociety",
  "reimagineTravelife",
  "shareYourLocalContent",
  "shareYourExperience",
];

/**
 * Routes to ignore in the sitemap
 */
export const SITE3_ROUTE_KEYS_TO_IGNORE_IN_SITEMAP: (keyof typeof en)[] = [
  "404",
  "country",
  "customerIndex",
  "login",
  "maintenance",
  "requestThanks",
  "shareYourLocalContent",
  "shareYourExperience",
];

/**
 * Routes that should be served from NextJS, after "upgrading" from the `default` locale to the preferred locale.
 **/
const SITE3_ROUTES: RouteTable = {};
// Lodash is forbidden in middleware so here's a nice for..of...
for (const [locale, keys] of Object.entries(LOCALISED_ROUTES)) {
  SITE3_ROUTES[locale] = {};
  for (const [key, path] of Object.entries(keys)) {
    if (SITE3_ROUTE_KEYS.find((page) => page === key)) {
      SITE3_ROUTES[locale][key] = path;
    }
  }
}

export const isSite3Route = (routeKey: string) => {
  return SITE3_ROUTE_KEYS.find((key) => key === routeKey);
};

/**
 * Get the route definition for the given locale and routing key. If not defined, the default en route is returned.
 * @param locale locale to find
 * @param routeKey routing key as defined in Locize
 */
export const getLocalisedRoute = (locale: string, routeKey: string) => {
  return (LOCALISED_ROUTES[locale] ?? LOCALISED_ROUTES["en"])?.[routeKey] ?? undefined;
};

export const routeHasParams = (route: string): boolean => {
  L10N_ROUTE_PARAM_REGEX.lastIndex = 0;
  return L10N_ROUTE_PARAM_REGEX.test(route);
};

export const localisedRouteToMatcher = (l10nRoute: string): RegExp => {
  // Extract a regex with named capturing groups for each parameter
  L10N_ROUTE_PARAM_REGEX.lastIndex = 0;
  let pathRegex = l10nRoute;
  for (const param of pathRegex.match(L10N_ROUTE_PARAM_REGEX) ?? []) {
    const match = param.match(/\{\{(.*)\}\}/);
    if (match != null) {
      pathRegex = pathRegex.replace(param, `(?<${match[1]}>[^/]+)`);
    }
  }

  return new RegExp(`^${pathRegex}$`);
};

/**
 * Find a suitable route definition for the given locale and real path fragment. If the route is not defined in the required locale, the route from en is used.
 * @param locale Locale to find
 * @param pathname relative URL path e.g. /articles/1/hello-world
 */
export const matchLocalisedRoute = (locale: Locale, pathname: string) => {
  return (
    Object.entries(LOCALISED_ROUTES[`${locale}`] ?? LOCALISED_ROUTES["en"])
      .map(([key, path]) => {
        const matcher = localisedRouteToMatcher(path);
        return [key, matcher] as [string, RegExp];
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .find(([_, matcher]) => {
        return matcher.test(pathname);
      })
  );
};

/**
 * Add params to a localised route.
 * @param template route template as defined in Locize routes
 * @param params object with key vals matching the template
 * @returns Rendered path
 * @throws if a parameter in the template wasn't defined
 */
export const addParamsToRoute = (template: string, params: Record<string, string | number>) => {
  let result = template;
  // Replace each route param in the reference en route with the actual required value
  for (const [paramName, paramVal] of Object.entries(params)) {
    result = result.replace(`{{${paramName}}}`, paramVal?.toString());
  }

  // Require that the configured translated route has the same number of params as the reference en route
  L10N_ROUTE_PARAM_REGEX.lastIndex = 0;

  const failedReplaces = [...result.matchAll(L10N_ROUTE_PARAM_REGEX)];
  if (failedReplaces?.length > 0) {
    const missingParams = failedReplaces.map((match) => match[0].match(/\{\{(.*)\}\}/)[1]);
    throw new Error(
      `Couldn't find route template param(s) ${missingParams}.\nTemplate: ${template}\nParams: ${params}`
    );
  }

  return result;
};

export interface TranslateRouteOptions {
  includeHost: boolean;
  includeLocale: boolean;
}

/**
 * Build a path by combining the route from the target locale and some params.
 * Will return a fully-qualified URL including host and locale by default.
 * @param locale required locale
 * @param routeKey required route
 * @param params object with key vals matching the template
 * @param options object to configure formate of returned URL.
 * @throws if a parameter in the template wasn't defined
 */
export const translateRoute = (
  locale: string,
  routeKey: string,
  params: Record<string, string | number> = {},
  options: Partial<TranslateRouteOptions> = {}
) => {
  const includeHost = options?.includeHost ?? true;
  const includeLocale = options?.includeLocale ?? true;

  const templateRoute = getLocalisedRoute(locale, routeKey);
  let path = addParamsToRoute(templateRoute, params);

  if (includeLocale) {
    path = `/${locale}` + path;
  }

  if (includeHost) {
    path = process.env.NEXT_PUBLIC_WEB_ROOT + path;
  }

  // remove trailing slash
  if (path.endsWith(`/`)) {
    path = path.slice(0, -1);
  }

  return path;
};

/**
 * Translate a real path by extracting params in path according to the localised matcher and placing them into templated path.
 * @param path a string to extract parameters from
 * @param matcher appropriate matcher to extract parameters from path
 * @param routeTemplate templated string for the path e.g. /articles/{{id}}/{{slug}}, sourced from Locize route keys
 */
export const translatePath = (path: string, matcher: RegExp, routeTemplate: string) => {
  // For the given routeMatcher, get the params (i.e. any {{strings}} in the pathname)
  matcher.lastIndex = 0;
  const { groups: localeRouteParams = {} } = path.match(matcher);

  return addParamsToRoute(routeTemplate, localeRouteParams);
};
